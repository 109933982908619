<template>
  <div id="app">
    <div id="top" class="top">
      <div class="df tab">
        <span
          class="mr20 cursor"
          :class="selected === item.type ? 'active' : ''"
          v-for="item in List"
          :key="item.type"
          @click="changeType(item.type)"
          >{{ item.name }}</span
        >
      </div>
      <img class="topimg" src="@/assets/top.png" alt="" />
    </div>
    <div id="middle" class="middle">
      <img class="middleimg" src="@/assets/middle.png" alt="" />
    </div>
    <div id="bot" class="bot">
      <div class="beian">
        <a
        target="_blank"
        style="color: #5c6b77"
        href="https://beian.miit.gov.cn/"
        >苏ICP备2022033812号</a
      >
        <span>©Copyright协鑫新能源.</span>
        <span>All rights reserved. Powered by Webfoss.</span>
      </div>
      <img class="botimg" src="@/assets/bot.png" alt="" />
    </div>
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  // components: {
  //   HelloWorld
  // }
  data () {
    return {
      selected: 1,
      List: [
        {
          type: 1,
          name: '首页'
        },
        {
          type: 2,
          name: 'APP展示'
        },
        {
          type: 3,
          name: '关于我们'
        },
        {
          type: 4,
          name: '联系我们'
        }
      ]
    }
  },
  methods: {
    tiao (i) {
      var cur = document.getElementById(i);
      this.getPosition(cur);
    },
    getPosition (e) {
      var t = e.offsetTop;
      // var l = e.offsetLeft;
      while (e == e.offsetParent) {
        t += e.offsetTop;
        // l += e.offsetLeft;
      }
      //方法一：$("html,body").animate({scrollTop:t},300);
      window.scrollTo({
        top: t - 10,
        behavior: "smooth"
      })
    },
    changeType (type) {
      this.selected = type
      if (type == 1) {
        this.tiao('top')
      }
      if (type == 2) {
        this.tiao('middle')
      }
      if (type == 3) {
        var cur = document.getElementById('middle')
        var t = cur.offsetTop;
        while (cur == cur.offsetParent) {
          t += cur.offsetTop;
        }
        window.scrollTo({
          top: t + 800,
          behavior: "smooth"
        })
      }
      if (type == 4) {
        this.tiao('bot')
      }
    }
  }
}
</script>

<style>
.df {
  display: flex;
}
.mr20 {
  margin-right: 70px;
}
.cursor {
  cursor: pointer;
}
.active {
  border-bottom: 3px solid #2a92ff;
}
body {
  margin: 0 !important;
}
#app {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #21b1ff;
  line-height: 42px;
}
.top {
  position: relative;
  margin: -1px;
  width: 100%;
}
.topimg {
  width: 100%;
}
.middle {
  margin: -1px;
  width: 100%;
}
.middleimg {
  width: 100%;
}
.bot {
  position: relative;
  margin: -1px;
  width: 100%;
}
.botimg {
  width: 100%;
}
.tab {
  position: fixed;
  z-index: 1;
  top: 30px;
  left: 40%;
}
.beian{
  width: 100%;
  position: absolute;
  font-size: 16px;
  color: #fff;
  bottom: 20px;
  text-align: center;
}
a{
  text-decoration: none;
  font-size: 16px;
  color: #fff !important;
}
</style>
